'use client';

import { useParams, useSearchParams } from '@mentimeter/next-navigation';
import { IntercomVisitorScript } from '@mentimeter/intercom';
import PageWrapper from '../_components/PageWrapper';
import SAMLStartHandler from '../_components/SAMLStartHandler';

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || '';

const SamlStartPage = () => {
  const { query } = useParams() ?? {};
  const searchParams = useSearchParams();
  const continuePath = searchParams?.get('continue');

  return (
    <>
      <IntercomVisitorScript intercomAppId={INTERCOM_APP_ID} />
      <PageWrapper>
        <SAMLStartHandler
          initialQuery={query?.toString()}
          continuePath={continuePath?.toString()}
        />
      </PageWrapper>
    </>
  );
};

export default SamlStartPage;
